
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.btn-box {
    margin-top: 20px;
    text-align: right;
}
